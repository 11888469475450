import React from 'react'

import UserDashboard from './views/users/UserDashboard';
import AdminDashboard from './views/admin/AdminDashboard';
import AdminAllUsers from './views/admin/AdminAllUsers';
import AdminAddUser from './views/admin/AdminAddUser';
import AdminEditUser from './views/admin/AdminEditUser';
import ActiveScans from './views/users/activeScans';
import StartQuickScan from './views/users/startQuickScan';
import ViewQuickScanReport from './views/users/viewQuickScanReport';
import Agents from './views/users/agents';
import ProjectVulnerabilities from './views/users/projectVulnerabilities';
import AddMirroringProject from './views/users/addProject';
import EditMirroringProject from './views/users/editProject';
import HostsUnderProtection from './views/users/hostsUnderProtection';
import AddHostUnderProtection from './views/users/addHostUnderProtection';
import EditHostUnderProtection from './views/users/editHostUnderProtection';
import HowToAddHostUnderProtection from './views/users/howToAddHostUnderProtection';
import AddAgent from './views/users/addAgent';
import Hosts from './views/users/hosts';
import EndPoints from './views/users/endPoints';
import Alerts from './views/users/alerts';
import PIIData from './views/users/piiData';
import PIIDataDetails from './views/users/piiDataDetails';
import Protection from './views/users/protection';
import Settings from './views/users/settings';


const routes = [
  { path: '/user-dashboard', name: 'User Dashboard', element: UserDashboard},
  { path: '/active-scans', name: 'Active Scans', element: ActiveScans}, 
  { path: '/start-active-scan', name: 'Start a Active Scan', element: StartQuickScan}, 
  { path: '/view-active-scan-report', name: 'View Active Scan Report', element: ViewQuickScanReport},
  { path: '/agents', name: 'Agents', element: Agents},
  { path: '/add-mirroring-project', name: 'AddMirroringProject', element: AddMirroringProject},
  { path: '/edit-mirroring-project', name: 'EditMirroringProject', element: EditMirroringProject},
  { path: '/agents', name: 'Agents', element: Agents},
  { path: '/add-agent', name: 'Add Agent', element: AddAgent},
  { path: '/project-vulnerabilities', name: 'Project vulnerabilities', element: ProjectVulnerabilities},
  { path: '/hosts', name: 'Hosts', element: Hosts},
  { path: '/endpoints', name: 'Endpoints', element: EndPoints},
  { path: '/alerts', name: 'Alerts', element: Alerts},
  { path: '/pii-data', name: 'PII Data', element: PIIData},
  { path: '/pii-data-details', name: 'PII Data Details', element: PIIDataDetails},
  { path: '/protection', name: 'Protection', element: Protection},
  { path: '/hosts-under-protection', name: 'Hosts Under Protection', element: HostsUnderProtection},
  { path: '/add-host-under-protection', name: 'Add Host Under Protection', element: AddHostUnderProtection},
  { path: '/edit-host-under-protection', name: 'Edit Host Under Protection', element: EditHostUnderProtection},
  { path: '/how-to-add-host-under-protection', name: 'How to Add Host Under Protection', element: HowToAddHostUnderProtection},
  { path: '/settings', name: 'Settings', element: Settings},   
  { path: '/admin-dashboard', name: 'Admin Dashboard', element: AdminDashboard}, 
  { path: '/admin-all-users', name: 'Admin All Users', element: AdminAllUsers}, 
  { path: '/admin-add-user', name: 'Admin Add User', element: AdminAddUser}, 
  { path: '/admin-edit-user', name: 'Admin Edit User', element: AdminEditUser}, 
]

export default routes 