import React, { useState, useEffect, useRef } from "react";

import axios from 'axios';

import {
  CCol,
  CRow,  
  CContainer,  
} from '@coreui/react'

import { Link } from "react-router-dom";

import successIcon from '../../assets/images/success-green-check-mark-icon.png'
import cancelIcon from '../../assets/images/cancel-icon.png'
import chromeLogo from '../../assets/images/google_chrome.png'

import usaFlag from '../../assets/images/us_flag.png'
import indiaFlag from '../../assets/images/india_flag.png'
import japanFlag from '../../assets/images/japan_flag.png'
import germanyFlag from '../../assets/images/germany_flag.png'
import canadaFlag from '../../assets/images/canada_flag.png'


import './style.css'; // Import your CSS file

import { HiOutlineDesktopComputer } from "react-icons/hi";
import { TbApi } from "react-icons/tb";
import { AiOutlineIssuesClose } from "react-icons/ai";
import { FiAlertCircle } from "react-icons/fi";
import { MdOutlinePersonalInjury } from "react-icons/md";
import { AiOutlineCloudServer } from "react-icons/ai";

import { AiFillFlag } from "react-icons/ai";
import { BsCollection } from "react-icons/bs";
import { CgNotes } from "react-icons/cg";

import { Shimmer, Breathing } from 'react-shimmer'

import Chart from 'react-apexcharts'
import CanvasJSReact from './canvasjs.react';
import ApexCharts from 'react-apexcharts';
import { extractHostAndEndpoint } from './utils';


const UserDashboard = () => {

  const random = () => Math.round(Math.random() * 100)
  const [dashboardData, setDashboardData] = useState({})
  const [vulnerabilityDistribution, setVulnerabilityDistribution] = useState({})
  const [topEndpoints, setTopEndpoints] = useState([])
  const [loadingStats, setLoadingStats] = useState(false)
  const [loadingVulnerabilityDistribution, setLoadingVulnerabilityDistribution] = useState(false)
  const [loadingTopEndpoints, setLoadingTopEndpoints] = useState(false)
  const [loadingVulnTrends, setLoadingVulnTrends] = useState(false)

  const [last5DaysVulnTrends, setLast5DaysVulnTrends] = useState([1, 2, 3, 4, 5])

  const [months, setMonths] = useState([])
  const [vulnCounts, setVulnCounts] = useState([])

  const ref = useRef(null); 

  useEffect(() => {

    fetchDashboardData();
    fetchVulnerabilityDistribution();
    fetchTopEndpoints();
    getLast5DaysVulnerabilityTrends();    

  }, []);     

  

  const getLast5DaysVulnerabilityTrends = () => {

    // Set from localStorage cache
    if (localStorage.getItem('months') && localStorage.getItem('vulnCounts')) {
      setMonths(JSON.parse(localStorage.getItem('months')));
      setVulnCounts(JSON.parse(localStorage.getItem('vulnCounts')));
      
    }else{
      setLoadingVulnTrends(true);
    }

    setLoadingVulnTrends(true);


    const endpoint = 'api/v1/users/getMonthlyVulnerabilityTrends';
    const token = localStorage.getItem('ASIToken');

    axios.get(endpoint, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {        

        const chartData = response.data;

        const months1 = response.data.activeScansByMonth.map(monthData => Object.keys(monthData)[0]);
        const vulnCounts1 = response.data.activeScansByMonth.map(monthData => Object.values(monthData)[0]);        

        setMonths(months1.reverse());
        setVulnCounts(vulnCounts1.reverse());     
        
        localStorage.setItem('months', JSON.stringify(response.data.activeScansByMonth.map(monthData => Object.keys(monthData)[0])));
        localStorage.setItem('vulnCounts', JSON.stringify(response.data.activeScansByMonth.map(monthData => Object.values(monthData)[0])));


        // Save into local storage to show from cache while it loads next time        
        setLoadingVulnTrends(false)
      })
      .catch(error => {
        console.error('Error fetching last 5 days vulns data:', error);
        setLoadingVulnTrends(false)
      });
  };



  const fetchDashboardData = () => {

    // Set from localStorage cache
    if (localStorage.getItem('dashboardData')) {
      
      setDashboardData(JSON.parse(localStorage.getItem('dashboardData')));
    }else{
      setLoadingStats(true);
    }


    const endpoint = 'api/v1/users/getUserDashboardCardsData';
    const token = localStorage.getItem('ASIToken');

    axios.get(endpoint, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        setDashboardData(response.data.dashboardData);

        // Save into local storage to show from cache while it loads next time
        localStorage.setItem('dashboardData', JSON.stringify(response.data.dashboardData));

        setLoadingStats(false)
      })
      .catch(error => {
        console.error('Error fetching dashboard data:', error);
        setLoadingStats(false)
      });
  };
  


  const fetchVulnerabilityDistribution = () => {

    // Set from localStorage cache
    if (localStorage.getItem('vulnerabilityCounts')) {
      console.log('if')
      setVulnerabilityDistribution(JSON.parse(localStorage.getItem('vulnerabilityCounts')));
    }else{
      console.log('else')
      setLoadingVulnerabilityDistribution(true);
    }


    const endpoint = 'api/v1/users/getVulnerabilityDistribution';
    const token = localStorage.getItem('ASIToken');

    axios.get(endpoint, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {


        setVulnerabilityDistribution(response.data.vulnerabilityCounts);

        // Save into local storage to show from cache while it loads next time
        localStorage.setItem('vulnerabilityCounts', JSON.stringify(response.data.vulnerabilityCounts));

        setLoadingVulnerabilityDistribution(false)
      })
      .catch(error => {
        //console.error('Error fetching dashboard data:', error);
        setLoadingVulnerabilityDistribution(false)
      });
  };


  const fetchTopEndpoints = () => {

    // Set from localStorage cache
   /* if (localStorage.getItem('topEndpoints')) {
      setTopEndpoints(JSON.parse(localStorage.getItem('topEndpoints')));
    }else{
      setLoadingTopEndpoints(true);
    }*/
   
    setLoadingTopEndpoints(true);

    const endpoint = 'api/v1/users/getTopEndPoints';
    const token = localStorage.getItem('ASIToken');

    axios.get(endpoint, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {


        setTopEndpoints(response.data.topEndpoints);

        // Save into local storage to show from cache while it loads next time
        //localStorage.setItem('topEndpoints', JSON.stringify(response.data.topEndpoints));

        setLoadingTopEndpoints(false)
      })
      .catch(error => {
        //console.error('Error fetching dashboard data:', error);
        setLoadingTopEndpoints(false)
      });
  };


  var totalVulnCount = vulnerabilityDistribution.vuln1Count +
                       vulnerabilityDistribution.vuln2Count + 
                       vulnerabilityDistribution.vuln3Count + 
                       vulnerabilityDistribution.vuln4Count + 
                       vulnerabilityDistribution.vuln5Count + 
                       vulnerabilityDistribution.vuln6Count + 
                       vulnerabilityDistribution.vuln7Count + 
                       vulnerabilityDistribution.vuln8Count + 
                       vulnerabilityDistribution.vuln9Count + 
                       vulnerabilityDistribution.vuln10Count + 
                       vulnerabilityDistribution.vuln11Count + 
                       vulnerabilityDistribution.vuln12Count + 
                       vulnerabilityDistribution.vuln13Count + 
                       vulnerabilityDistribution.vuln14Count + 
                       vulnerabilityDistribution.vuln15Count + 
                       vulnerabilityDistribution.vuln16Count + 
                       vulnerabilityDistribution.vuln17Count + 
                       vulnerabilityDistribution.vuln18Count;

  var vuln1Percent = ((vulnerabilityDistribution.vuln1Count / totalVulnCount)*100).toFixed(1);
  var vuln2Percent = ((vulnerabilityDistribution.vuln2Count / totalVulnCount)*100).toFixed(1);
  var vuln3Percent = ((vulnerabilityDistribution.vuln3Count / totalVulnCount)*100).toFixed(1);
  var vuln4Percent = ((vulnerabilityDistribution.vuln4Count / totalVulnCount)*100).toFixed(1);
  var vuln5Percent = ((vulnerabilityDistribution.vuln5Count / totalVulnCount)*100).toFixed(1);
  var vuln6Percent = ((vulnerabilityDistribution.vuln6Count / totalVulnCount)*100).toFixed(1);
  var vuln7Percent = ((vulnerabilityDistribution.vuln7Count / totalVulnCount)*100).toFixed(1);
  var vuln8Percent = ((vulnerabilityDistribution.vuln8Count / totalVulnCount)*100).toFixed(1);
  var vuln9Percent = ((vulnerabilityDistribution.vuln9Count / totalVulnCount)*100).toFixed(1);
  var vuln10Percent = ((vulnerabilityDistribution.vuln10Count / totalVulnCount)*100).toFixed(1);
  var vuln11Percent = ((vulnerabilityDistribution.vuln11Count / totalVulnCount)*100).toFixed(1);
  var vuln12Percent = ((vulnerabilityDistribution.vuln12Count / totalVulnCount)*100).toFixed(1);
  var vuln13Percent = ((vulnerabilityDistribution.vuln13Count / totalVulnCount)*100).toFixed(1);
  var vuln14Percent = ((vulnerabilityDistribution.vuln14Count / totalVulnCount)*100).toFixed(1);
  var vuln15Percent = ((vulnerabilityDistribution.vuln15Count / totalVulnCount)*100).toFixed(1);
  var vuln16Percent = ((vulnerabilityDistribution.vuln16Count / totalVulnCount)*100).toFixed(1);
  var vuln17Percent = ((vulnerabilityDistribution.vuln17Count / totalVulnCount)*100).toFixed(1);
  var vuln18Percent = ((vulnerabilityDistribution.vuln18Count / totalVulnCount)*100).toFixed(1);


  var lowCount = 0;
  var mediumCount = 0;
  var highCount = 0;
  var criticalCount = 0;


  var labelsArray = [];

  if (vulnerabilityDistribution.vuln1Count > 0) {
    labelsArray.push('Broken Object Level Authorization ('+vuln1Percent+'%)');
    highCount = highCount + 1;
  }
  if (vulnerabilityDistribution.vuln2Count > 0) {
    labelsArray.push('Sensitive Data in Path Params ('+vuln2Percent+'%)');
    highCount = highCount + 1;
  }
  if (vulnerabilityDistribution.vuln3Count > 0) {
    labelsArray.push('Basic Authentication Detected ('+vuln3Percent+'%)');
    mediumCount = mediumCount + 1;
  }
  if (vulnerabilityDistribution.vuln4Count > 0) {
    labelsArray.push('Endpoint Not Secured by SSL ('+vuln4Percent+'%)');
    highCount = highCount + 1;
  }
  if (vulnerabilityDistribution.vuln5Count > 0) {
    labelsArray.push('Unauthenticated Endpoint Returning Sensitive Data ('+vuln5Percent+'%)');
    highCount = highCount + 1;
  }
  if (vulnerabilityDistribution.vuln6Count > 0) {
    labelsArray.push('Sensitive Data in Query Params ('+vuln6Percent+'%)');
    highCount = highCount + 1;
  }
  if (vulnerabilityDistribution.vuln7Count > 0) {
    labelsArray.push('PII Data Detected in Response ('+vuln7Percent+'%)');
    highCount = highCount + 1;
  }
  if (vulnerabilityDistribution.vuln8Count > 0) {
    labelsArray.push('HTTP Verb Tampering Possible ('+vuln8Percent+'%)');
    mediumCount = mediumCount + 1;
  }
  if (vulnerabilityDistribution.vuln9Count > 0) {
    labelsArray.push('Content Type Injection Possible ('+vuln9Percent+'%)');
    highCount = highCount + 1;
  }
  if (vulnerabilityDistribution.vuln10Count > 0) {
    labelsArray.push('Security Headers not Enabled on Host ('+vuln10Percent+'%)');
    mediumCount = mediumCount + 1;
  }
  if (vulnerabilityDistribution.vuln11Count > 0) {
    labelsArray.push('Resource Deletion Possible ('+vuln11Percent+'%)');
    highCount = highCount + 1;
  }
  if (vulnerabilityDistribution.vuln12Count > 0) {
    labelsArray.push('Broken Authentication ('+vuln12Percent+'%)');
    highCount = highCount + 1;
  }
  if (vulnerabilityDistribution.vuln13Count > 0) {
    labelsArray.push('Excessive Data Exposure ('+vuln13Percent+'%)');
    mediumCount = mediumCount + 1;
  }
  if (vulnerabilityDistribution.vuln14Count > 0) {
    labelsArray.push('Injection('+vuln14Percent+'%)');
    highCount = highCount + 1;
  }
  if (vulnerabilityDistribution.vuln15Count > 0) {
    labelsArray.push('XSS Vulnerability Found('+vuln15Percent+'%)');
    highCount = highCount + 1;
  }
  if (vulnerabilityDistribution.vuln16Count > 0) {
    labelsArray.push('Wallet Hijacking Possible ('+vuln16Percent+'%)');
    highCount = highCount + 1;
  }
  if (vulnerabilityDistribution.vuln17Count > 0) {
    labelsArray.push('Pre Image Attack Possible ('+vuln17Percent+'%)');
    highCount = highCount + 1;
  }
  if (vulnerabilityDistribution.vuln18Count > 0) {
    labelsArray.push('Lack of Resource & Rate Limiting ('+vuln18Percent+'%)');
    highCount = highCount + 1;
  }

  var severityLevelsArray = ['LOW', 'MEDIUM', 'HIGH', 'CRITICAL']

  var severityDataArray = [lowCount, mediumCount, highCount, criticalCount];
  var severityColorsArray = ['#00FF00', '#FFD700', '#FF0000', '#800080'];
  const severityChartSeries = severityDataArray;    



  var dataArray = [];
  var bgColorArray = [];

  const pieColors = [
    '#FF5733', '#33A02C', '#1F77B4', '#FF8C00',
  '#32CD32', '#6495ED', '#FF4500', '#228B22',
  '#4169E1', '#FF6347', '#7CFC00', '#6495ED',
  '#FF5733', '#33A02C', '#1F77B4', '#FF8C00',
  '#32CD32', '#4169E1'
  ];  
  
  
  
  if (vulnerabilityDistribution.vuln1Count > 0) {
    dataArray.push(vulnerabilityDistribution.vuln1Count);
    bgColorArray.push(pieColors[0]);
  }

  if (vulnerabilityDistribution.vuln2Count > 0) {
    dataArray.push(vulnerabilityDistribution.vuln2Count);
    bgColorArray.push(pieColors[1]);
  }

  if (vulnerabilityDistribution.vuln3Count > 0) {
    dataArray.push(vulnerabilityDistribution.vuln3Count);
    bgColorArray.push(pieColors[2]);
  }

  if (vulnerabilityDistribution.vuln4Count > 0) {
    dataArray.push(vulnerabilityDistribution.vuln4Count);
    bgColorArray.push(pieColors[3]);
  }

  if (vulnerabilityDistribution.vuln5Count > 0) {
    dataArray.push(vulnerabilityDistribution.vuln5Count);
    bgColorArray.push(pieColors[4]);
  }

  if (vulnerabilityDistribution.vuln6Count > 0) {
    dataArray.push(vulnerabilityDistribution.vuln6Count);
    bgColorArray.push(pieColors[5]);
  }
  if (vulnerabilityDistribution.vuln7Count > 0) {
    dataArray.push(vulnerabilityDistribution.vuln7Count);
    bgColorArray.push(pieColors[6]);
  }

  if (vulnerabilityDistribution.vuln8Count > 0) {
    dataArray.push(vulnerabilityDistribution.vuln8Count);
    bgColorArray.push(pieColors[7]);
  }

  if (vulnerabilityDistribution.vuln9Count > 0) {
    dataArray.push(vulnerabilityDistribution.vuln9Count);
    bgColorArray.push(pieColors[8]);
  }

  if (vulnerabilityDistribution.vuln10Count > 0) {
    dataArray.push(vulnerabilityDistribution.vuln10Count);
    bgColorArray.push(pieColors[9]);
  }

  if (vulnerabilityDistribution.vuln11Count > 0) {
    dataArray.push(vulnerabilityDistribution.vuln11Count);
    bgColorArray.push(pieColors[10]);
  }
  if (vulnerabilityDistribution.vuln12Count > 0) {
    dataArray.push(vulnerabilityDistribution.vuln12Count);
    bgColorArray.push(pieColors[11]);
  }
  if (vulnerabilityDistribution.vuln13Count > 0) {
    dataArray.push(vulnerabilityDistribution.vuln13Count);
    bgColorArray.push(pieColors[12]);
  }
  if (vulnerabilityDistribution.vuln14Count > 0) {
    dataArray.push(vulnerabilityDistribution.vuln14Count);
    bgColorArray.push(pieColors[13]);
  }
  if (vulnerabilityDistribution.vuln15Count > 0) {
    dataArray.push(vulnerabilityDistribution.vuln15Count);
    bgColorArray.push(pieColors[14]);
  }
  if (vulnerabilityDistribution.vuln16Count > 0) {
    dataArray.push(vulnerabilityDistribution.vuln16Count);
    bgColorArray.push(pieColors[15]);
  }
  if (vulnerabilityDistribution.vuln17Count > 0) {
    dataArray.push(vulnerabilityDistribution.vuln17Count);
    bgColorArray.push(pieColors[16]);
  }
  if (vulnerabilityDistribution.vuln18Count > 0) {
    dataArray.push(vulnerabilityDistribution.vuln18Count);
    bgColorArray.push(pieColors[17]); 
  }
 

  const vulnDistrochartOptions = {
    labels: labelsArray,
    colors: bgColorArray,
    legend: {
      position: 'right',
      verticalAlign: 'middle',
    },    
  };
 

  const severitychartOptions = {
    labels: severityLevelsArray,
    colors: severityColorsArray,
    legend: {
      position: 'right',
      verticalAlign: 'middle',
    },    
  };
 


  const vulnDistroChartSeries = dataArray;    

  const vulnLabels = [
  "Broken Object Level Authorization ("+vuln1Percent+'%)',
  "Sensitive Data in Path Params ("+vuln2Percent+'%)',
  "Basic Authentication Detected ("+vuln3Percent+'%)',
  "Endpoint Not Secured by SSL ("+vuln4Percent+'%)',
  "Unauthenticated Endpoint Returning Sensitive Data ("+vuln5Percent+'%)',
  "Sensitive Data in Query Params ("+vuln6Percent+'%)',
  "PII Data Detected in Response ("+vuln7Percent+'%)',
  "HTTP Verb Tampering Possible ("+vuln8Percent+'%)',
  "Content Type Injection Possible ("+vuln9Percent+'%)',
  "Security Headers Not Enabled on Host ("+vuln10Percent+'%)',
  "Resource Deletion Possible ("+vuln11Percent+'%)',
  "Broken Authentication ("+vuln12Percent+'%)',
  "Excessive Data Exposure ("+vuln13Percent+'%)',
  "Injection Possible ("+vuln14Percent+'%)',
  "XSS Vulnerability Found ("+vuln15Percent+'%)',
  "Wallet Hijacking Possible ("+vuln16Percent+'%)',
  "Pre Image Attack Possible ("+vuln17Percent+'%)',
  "Lack of Resource & Rate Limiting ("+vuln18Percent+'%)',
];



  var lineChartSeries = [];

for (let vulnIndex = 0; vulnIndex <= 17; vulnIndex++) {

  const seriesData = vulnCounts.map(monthCount => monthCount[`vuln${vulnIndex+1}Count`]);

  
  lineChartSeries.push({
    name: vulnLabels[vulnIndex],
    data: seriesData,
  });
}



 

// Creating the chart options
 var lineChartOptions = {

    chart: {
      type: 'line',
      height: 350,
      animations: {
        enabled: true,
        easing: 'linear',
        dynamicAnimation: {
          speed: 1000
        }
      }
    },
    xaxis: {
      categories: months,
      labels: {
        style: {
          colors: '#ffffff' // Set x-axis labels color to white
        }
      }
    },
    yaxis: {
      labels: {
        style: {
          colors: '#ffffff' // Set y-axis labels color to white
        }
      }
    },
    series: lineChartSeries,
    legend: {
      labels: {
        colors: '#ffffff' // Set the legend label colors to white
      },
      containerMargin: {
        top: 20 // Add top margin of 20 pixels
      }
    },
    tooltip: {
      theme: 'dark', // Use dark theme for the tooltip
      style: {
        color: '#000000' // Set the text color on the tooltip to black
      }
    }
};



  const topRulesInLast24Hrsoptions = {
    chart: {
      type: 'bar',
      height: 350,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        endingShape: 'flat',
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: ['1', '960020', '960010', '973300', '973335', '960015', '970901', '990012', '960035', '950901'],
      title: {
        text: 'Values',
      },
    },
    yaxis: {
      title: {
        text: 'Events',
      },
      labels: {
        formatter: function (value) {
          return value.toString(); // Converts the labels to strings
        },
      },
    },
    colors: ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0', '#546E7A', '#26a69a', '#d05ce3', '#5c4299', '#546e7a'],
  };

  const topRulesInLast24HrsSeries = [
    {
      name: 'Values',
      data: [2500, 30000, 5000, 15000, 45000, 10000, 6000, 2000, 35000, 7500],
    },
  ];


  //

  const topTargetsInLast24Hrsoptions = {
    chart: {
      type: 'bar',
      height: 350,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        endingShape: 'flat',
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: ['test.example.org',
                   'www4.example.org', 
                   'secure.example.org', 
                   'example.org', 
                   'www.example.org', 
                   'www8.example.org', 
                   'site.example.org', 
                   'intranet.example.org', 
                   'www.insecure.example.org', 
                   '192.168.10.100'
                  ],
      title: {
        text: 'Values',
      },
    },
    yaxis: {
      title: {
        text: 'Events',
      },
      labels: {
        formatter: function (value) {
          return value.toString(); // Converts the labels to strings
        },
      },
    },
    colors: ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0', '#546E7A', '#26a69a', '#d05ce3', '#5c4299', '#546e7a'],
  };

  const topTargetsInLast24HrsSeries = [
    {
      name: 'Values',
      data: [0, 13429, 2242, 6727, 20182, 4490, 2694, 898, 15701, 3362],
    },
  ];



  //

  const eventsPerStatusOptions = {
    chart: {
      type: 'pie',
      height: 350,
    },
    labels: [
      '200 OK',
      '500 Internal Server Error',
      '401 Unauthorized',
      '304 OK',
      '404 Not Found',
      '302 Found',
      '206 Partial Content',
      '405 Method Not Allowed',
      '503 Service Unavailable',
      '502 Bad Gateway',
    ],
    colors: [
      '#008FFB',
      '#00E396',
      '#FEB019',
      '#FF4560',
      '#775DD0',
      '#546E7A',
      '#26a69a',
      '#d05ce3',
      '#5c4299',
      '#546e7a',
    ],
  };

  const eventsPerStatusSeries = [37, 29, 10, 7, 15, 8, 14, 5, 11, 6]; // Random values for the sections


  //

  const topSourcesInLast24Hrsoptions = {
    chart: {
      type: 'bar',
      height: 350,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        endingShape: 'flat',
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: ['10.184.158.101',
                   '10.191.241.59', 
                   '10.21.230.133', 
                   '10.16.10.80', 
                   '10.220.33.62', 
                   '10.47.208.37', 
                   '10.4.182.14', 
                   '10.142.86.223', 
                   '10.16.127.171', 
                   '10.74.157.66'
                  ],
      title: {
        text: 'Values',
      },
    },
    yaxis: {
      title: {
        text: 'Events',
      },
      labels: {
        formatter: function (value) {
          return value.toString(); // Converts the labels to strings
        },
      },
    },
    colors: ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0', '#546E7A', '#26a69a', '#d05ce3', '#5c4299', '#546e7a'],
  };

  const topSourcesInLast24HrsSeries = [
    {
      name: 'Values',
      data: [0, 13429, 2242, 6727, 20182, 4490, 2694, 898, 15701, 3362],
    },
  ];

  //
  

  const apiActivityChartData = [
    {
      name: 'Total Calls',
      data: [1500000, 1400000, 1300000, 1200000, 1100000, 1000000, 900000, 800000, 700000, 600000, 500000, 400000, 300000, 200000],
      color: '#ff5733', // Specify the color for this series
    },
    {
      name: 'Attack Calls',
      data: [1400000, 1300000, 1200000, 1100000, 1000000, 900000, 800000, 700000, 600000, 500000, 400000, 300000, 200000, 100000],
      color: '#33ff57', // Specify the color for this series
    },
    {
      name: 'Blocked Calls',
      data: [1300000, 1200000, 1100000, 1000000, 900000, 800000, 700000, 600000, 500000, 400000, 300000, 200000, 100000, 80000],
      color: '#5733ff', // Specify the color for this series
    },
  ];

  

  // X-axis labels
  const apiActivityCategories = [
    'Jun 6', 'Jun 7', 'Jun 8', 'Jun 9', 'Jun 10', 'Jun 11', 'Jun 12', 'Jun 13', 'Jun 14', 'Jun 15', 'Jun 16', 'Jun 17', 'Jun 18', 'Jun 19'
  ];

  const apiActivityOptions = {
    chart: {
      height: 350,
      type: 'line',
    },
    stroke: {
      curve: 'straight'
    },
    tooltip: {
      theme: 'dark', // Set the tooltip theme to dark
    },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.5
      },
    },
    xaxis: {
      categories: apiActivityCategories,
    },
    yaxis: {
      reversed: true,
      min: 80000,
      max: 2000000,
    },
  };


   //Status codes chart
   const statusCodeChartOptions = {
    chart: {
      type: 'bar',
      height: 350,
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    xaxis: {
      categories: ['200', '500', '404'],
      labels: {
        style: {
          fontSize: '14px',
        },
      },
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return value / 1000000 + 'M';
        },
        style: {
          fontSize: '14px',
        },
      },
      min: 0,
      max: 25000000,
    },
  };

  const statusCodeChartSeries = [
    {
      name: 'Status Codes',
      data: [0, 250000, 500000],
    },
  ];


  // Attacks bar chart
  const attacksBarChartOptions = {
    chart: {
      type: 'bar',
      height: 350,
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    xaxis: {
      categories: ['SSRF', 'SQLI', 'RCE', 'XSS', 'GQL_INTROSPECT'],
      labels: {
        style: {
          fontSize: '14px',
        },
      },
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return value / 1000000 + 'M';
        },
        style: {
          fontSize: '14px',
        },
      },
      min: 0,
      max: 500000,
    },
  };

  const attacksBarChartSeries = [
    {
      name: 'Status Codes',
      data: [234567, 98765, 342156, 185432, 451289 ],
    },
  ];


  // Locations bar chart
  const locationsBarChartOptions = {
    chart: {
      type: 'bar',
      height: 350,
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    xaxis: {
      categories: ['United States', 'Japan', 'India', 'Canada', 'Germany', 'South Korea'],
      labels: {
        style: {
          fontSize: '14px',
        },
      },
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return value / 1000000 + 'M';
        },
        style: {
          fontSize: '14px',
        },
      },
      min: 0,
      max: 1000000,
    },
  };

  const locationsBarChartSeries = [
    {
      name: 'Status Codes',
      data: [643218, 195723, 879462, 512349, 346987, 734567 ],
    },
  ];
 

  // Web response chart

  const webResponseChartData = [
    {
      name: 'DNS',
      data: [380, 200, 150, 250, 150, 300],
    },
    {
      name: 'Connect',
      data: [200, 150, 350, 250, 200, 150],
    },
    {
      name: 'Send',
      data: [50, 150, 200, 100, 120, 50],
    },
    {
      name: 'Wait',
      data: [120, 80, 60, 80, 100, 70],
    },
    {
      name: 'Recv',
      data: [180, 80, 100, 150, 120, 180],
    },
  ];

  const webResponseCategories = ['11:00', '11:30', '12:00', '12:30', '13:00', '13:30'];

  const webResponseOptions = {
    chart: {
      type: 'bar',
      stacked: false,
      height: 350,
    },
    xaxis: {
      categories: webResponseCategories,
    },
    tooltip: {
      theme: 'dark', // Set the tooltip theme to dark
    },
    yaxis: {
      min: 0,
      max: 150,
      tickAmount: 4,
      labels: {
        formatter: function (value) {
          return value + ' ms';
        },
      },
    },
    legend: {
      position: 'top',
    },
  };


  // Web throughput

  const webThroughputChartData = [
    {
      name: 'Content Size',
      data: [19.70, 19.75, 19.78, 19.78, 19.79, 19.80, ],
    },
    {
      name: 'Throughput',
      data: [19.70, 19.74, 19.78, 19.77, 19.78, 19.79, ],
    },
  ];

  const webThroughputCategories = ['11:00', '11:30', '12:00', '12:30', '13:00', '13:30'];

  const webThroughputOptions = {
    chart: {
      height: 350,
      type: 'line',
    },
    xaxis: {
      categories: webThroughputCategories,
    },
    tooltip: {
      theme: 'dark', // Set the tooltip theme to dark
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return value.toFixed(2) + ' kbps';
        },
      },
      min: 19.70,
      max: 20.00,
    },
  };


  // Web performace
  const yValues = [300, 400, 500, 600, 700];
  const numDataPoints = 50;

  const seriesNames = [
    'singapore',
    'taipei',
    'tokyo',
    'sydney',
    'omaha',
    'seattle',
    'new-jersey',
    'portland',
    'frankfurt',
    'los-angeles',
    'amsterdam',
    'paris',
    'new-york',
    'silicon-valley',
  ];
  
  const getRandomValue = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;

  const webPerfSeriesData = seriesNames.map(seriesName => {
    const seriesData = [];

    for (let i = 0; i < numDataPoints; i++) {
      seriesData.push({
        x: getRandomValue(100, 1600), // Adjust the range as needed
        y: getRandomValue(0, 700),
      });
    }


    return {
      name: seriesName,
      data: seriesData,
    };
  });

 // Generate random colors for each series
 const WebPerfSeriesColors = Array.from({ length: 14 }, () => `#${Math.floor(Math.random()*16777215).toString(16)}`);

 const WebPerfChartOptions = {
   chart: {
     type: 'scatter',
     zoom: {
       enabled: false,
     },
   },
   xaxis: {
     show: false,
   },
   
   tooltip: {
    theme: 'dark', // Set the tooltip theme to dark
  },
   yaxis: {
     categories: yValues,
   },
   markers: {
    size: 2, // Adjust the marker size here
  },
  grid: {
    padding: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
    row: {
      colors: [], // You can specify colors for rows
      opacity: 0.2,
    },
    column: {
      colors: [], // You can specify colors for columns
      opacity: 0.2,
    },
  }
 };


 //
 const loginSuccessByUserPieChartData = [60, 40]; // 60% for "root" and 40% for "kitten"

// Colors for the sections
const loginSuccessByUserColors = ['#FF5733', '#3366FF']; // Replace with your desired colors

// Chart options
const loginSuccessByUserPieChartOptions = {
  chart: {
    type: 'pie',
  },
  labels: ['root', 'kitten'],
  colors: loginSuccessByUserColors,
};

//

const loginFailureByUserPieChartData = [70, 30]; // 60% for "root" and 40% for "kitten"

// Colors for the sections
const loginFailureByUserColors = ['#FF5733', '#3366FF']; // Replace with your desired colors

// Chart options
const loginFailureByUserPieChartOptions = {
  chart: {
    type: 'pie',
  },
  labels: ['root', 'kitten'],
  colors: loginFailureByUserColors,
};

//

const xLabels = [
  'Tue Sep 15', 'Wed Sep 16', 'Thu Sep 17', 'Fri Sep 18',
  'Sat Sep 19', 'Sun Sep 20', 'Mon Sep 21', 'Tue Sep 22',
];

// Data for the Y-axis
const yData = [2, 4, 6];

// Data for the series
const failedLoginsSeriesData = [
  {
    name: 'commandsoc.internal.truvis.cat',
    data: [8, 12, 10, 15, 13, 20, 18, 22],
  },
  {
    name: 'commandsoc',
    data: [10, 8, 15, 12, 16, 14, 18, 20],
  },
  {
    name: 'observium.internal.truvis.cat',
    data: [6, 4, 8, 10, 7, 9, 12, 14],
  },
  {
    name: 'pinglatplotter.internal.truvis.cat',
    data: [4, 5, 6, 8, 9, 6, 5, 7],
  },
];

// Chart options
const failedLoginsLineChartOptions = {
  chart: {
    id: 'line-chart',
    type: 'line',
  },
  xaxis: {
    categories: xLabels,
  },
  yaxis: {
    labels: {
      formatter: function (value) {
        return value + ' ms';
      },
    },
  },
};


////
const xLabels1 = [
  'Tue Sep 15', 'Wed Sep 16', 'Thu Sep 17', 'Fri Sep 18',
  'Sat Sep 19', 'Sun Sep 20', 'Mon Sep 21', 'Tue Sep 22',
];

// Data for the Y-axis
const yData1 = [2, 4, 6];

// Data for the series
const successfullLoginsSeriesData = [
  {
    name: 'commandsoc.internal.truvis.cat',
    data: [8, 12, 10, 15, 13, 20, 18, 22],
  },
  {
    name: 'commandsoc',
    data: [10, 8, 15, 12, 16, 14, 18, 20],
  },
  {
    name: 'observium.internal.truvis.cat',
    data: [6, 4, 8, 10, 7, 9, 12, 14],
  },
  {
    name: 'pinglatplotter.internal.truvis.cat',
    data: [4, 5, 6, 8, 9, 6, 5, 7],
  },
];

// Chart options
const successfullLoginsLineChartOptions = {
  chart: {
    id: 'line-chart',
    type: 'line',
  },
  xaxis: {
    categories: xLabels1,
  },
  yaxis: {
    labels: {
      formatter: function (value) {
        return value + ' ms';
      },
    },
  },
};


const vulnerabilities = [
  'Broken Object Level Authorization',
  'Sensitive Data in Path Params',
  'Basic Authentication Detected',
  'Endpoint Not Secured by SSL',
  'Unauthenticated Endpoint Returning Sensitive Data',
  'Sensitive Data in Query Params',
  'PII Data Detected in Response',
  'HTTP Verb Tampering Possible',
  'Content Type Injection Possible',
  'Security Headers not Enabled on Host',
  'Resource Deletion Possible',
  'Broken Authentication',
  'Excessive Data Exposure',
  'Injection',
  'Wallet Hijacking Possible',
  'Pre Image Attack Possible',
  'Lack of Resource & Rate Limiting',
];

const severities = [
  'HIGH',
  'HIGH',
  'MEDIUM',
  'HIGH',
  'HIGH',
  'HIGH',
  'HIGH',
  'MEDIUM',
  'HIGH',
  'MEDIUM',
  'HIGH',
  'HIGH',
  'MEDIUM',
  'HIGH',
  'HIGH',
  'HIGH',
  'HIGH',
  'HIGH',
];

const colors = {
  HIGH: '#FF4560',
  MEDIUM: '#FFC260',
  LOW: '#5E72E4',
};


const theData = vulnerabilities.map((vuln, index) => ({
  x: vuln,
  y: severities[index],
  fill: colors[severities[index]],
}));

const theOptions = {
  plotOptions: {
    bar: {
      horizontal: false,
    },
  },
  xaxis: {
    categories: vulnerabilities,
  },
  yaxis: {
    labels: {
      show: false, // To hide the default numeric labels on the Y-axis
    },
  },
};


console.log('lineChartSeries:',lineChartSeries)


  return (
    <>

      <CContainer>

        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', flexWrap:'wrap' }}>

          <div className="dashboardCard" style={{
            display: 'flex', flexDirection: 'row', backgroundColor: '#fff',
            borderRadius: 10, padding: 20, 
          }}>

            <Link to="/active-scans" 
               style={{ textDecoration: 'none', background:'transparent', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

              <BsCollection size={40} style={{ color: '#ffaa05' }} />

            </Link>

            <Link to="/active-scans" style={{ textDecoration: 'none' }}>

              <div className="dashboardLinkDiv">

                {loadingStats ?
                 <div style={{display:'flex', flexDirection:'column', height:35, justifyContent:'space-between'}}>
                  <Breathing width={100} height={5} />
                  <Breathing width={70} height={5} style={{marginTop:3}}/>
                  <Breathing width={50} height={5} style={{marginTop:3}}/>
                 </div>
                  :
                  <h3 style={{ color: '#2f2f3b', fontWeight: 600, }}>{dashboardData.collectionsCount}</h3>
                }

                <span style={{ fontSize: 16, color: '#2f2f3b', }}>API Collections</span>
              </div>
            </Link>

          </div>


          <div className="dashboardCard" style={{ display: 'flex', flexDirection: 'row', borderRadius: 10, padding: 20,  }}>

            <Link to="/endpoints" style={{ textDecoration: 'none', background:'transparent', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <TbApi size={40} style={{ color: '#7366ff' }} />
            </Link>

            <Link to="/endpoints" style={{ textDecoration: 'none' }}>
              <div className="dashboardLinkDiv">
                {loadingStats ?
                  <div style={{display:'flex', flexDirection:'column', height:35, justifyContent:'space-between'}}>
                  <Breathing width={100} height={5} />
                  <Breathing width={70} height={5} style={{marginTop:3}}/>
                  <Breathing width={50} height={5} style={{marginTop:3}}/>
                 </div>
                  :
                  <h3 style={{ color: '#2f2f3b', fontWeight: 600, }}>{dashboardData.endPointsCount}</h3>
                }
                <span style={{ fontSize: 16, color: '#7366ff', }}>API Endpoints</span>
              </div>
            </Link>

          </div>


          <div className="dashboardCard" style={{
            display: 'flex', flexDirection: 'row', borderRadius: 10, padding: 20,           
          }}>

            <Link to="/agents" style={{ textDecoration: 'none', background:'transparent', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <HiOutlineDesktopComputer size={40} style={{ color: '#f73164', }} />
            </Link>

            <Link to="/agents" style={{ textDecoration: 'none', width: '50%', }}>
              <div className="dashboardLinkDiv">
                {loadingStats ?
                  <div style={{display:'flex', flexDirection:'column', height:35, justifyContent:'space-between'}}>
                  <Breathing width={100} height={5} />
                  <Breathing width={70} height={5} style={{marginTop:3}}/>
                  <Breathing width={50} height={5} style={{marginTop:3}}/>
                 </div>
                  :
                  <h3 style={{ color: '#2f2f3b', fontWeight: 600, }}>{dashboardData.agentsCount}</h3>
                }
                <span style={{ fontSize: 16, color: '#f73164', }}>Agents</span>
              </div>
            </Link>

          </div>              


          <div className="dashboardCard secondRow" style={{ display: 'flex', flexDirection: 'row', borderRadius: 10, padding: 20, }}>

            <Link to="/alerts" style={{ textDecoration: 'none', background:'transparent', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <AiOutlineIssuesClose size={40} style={{ color: '#ffaa05' }} />
            </Link>

            <Link to="/alerts" style={{ textDecoration: 'none' }}>
              <div className="dashboardLinkDiv">
                {loadingStats ?
                  <div style={{display:'flex', flexDirection:'column', height:35, justifyContent:'space-between'}}>
                  <Breathing width={100} height={5} />
                  <Breathing width={70} height={5} style={{marginTop:3}}/>
                  <Breathing width={50} height={5} style={{marginTop:3}}/>
                 </div>
                  :
                  <h3 style={{ color: '#2f2f3b', fontWeight: 600, }}>{dashboardData.vulnerabilitiesCount}</h3>
                }
                <span style={{ fontSize: 16, color: '#ffaa05', }}>Vulnerabilities</span>
              </div>
            </Link>
          </div>

          <div className="dashboardCard secondRow" style={{ display: 'none', flexDirection: 'row', borderRadius: 10, padding: 20,  }}>

            <Link to="/alerts" style={{ textDecoration: 'none', background:'transparent', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <FiAlertCircle size={40} style={{ color: '#54ba4a' }} />
            </Link>

            <Link to="/alerts" style={{ textDecoration: 'none' }}>
              <div className="dashboardLinkDiv">
                {loadingStats ?
                  <div style={{display:'flex', flexDirection:'column', height:35, justifyContent:'space-between'}}>
                  <Breathing width={100} height={5} />
                  <Breathing width={70} height={5} style={{marginTop:3}}/>
                  <Breathing width={50} height={5} style={{marginTop:3}}/>
                 </div>
                  :
                  <h3 style={{ color: '#2f2f3b', fontWeight: 600, }}>{dashboardData.alertsCount}</h3>
                }
                <span style={{ fontSize: 16, color: '#54ba4a', }}>Alerts</span>
              </div>
            </Link>

          </div>

          <div className="dashboardCard secondRow" style={{ display: 'flex', flexDirection: 'row', borderRadius: 10, padding: 20,  }}>

            <Link to="/pii-data" style={{ textDecoration: 'none', background:'transparent', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <MdOutlinePersonalInjury size={40} style={{ color: '#f73164' }} />
            </Link>

            <Link to="/pii-data" style={{ textDecoration: 'none' }}>
              <div className="dashboardLinkDiv">
                {loadingStats ?
                  <div style={{display:'flex', flexDirection:'column', height:35, justifyContent:'space-between'}}>
                  <Breathing width={100} height={5} />
                  <Breathing width={70} height={5} style={{marginTop:3}}/>
                  <Breathing width={50} height={5} style={{marginTop:3}}/>
                 </div>
                  :
                  <h3 style={{ color: '#2f2f3b', fontWeight: 600, }}>{dashboardData.piiDataFieldsCount}</h3>
                }
                <span style={{ fontSize: 16, color: '#f73164', }}>PII Data Fields</span>
              </div>
            </Link>
          </div>

          <div className="dashboardCard secondRow" 
          style={{ display: 'none', flexDirection: 'row', borderRadius: 10, padding: 20, }}>

            <Link to="/protection" style={{ textDecoration: 'none', background:'transparent', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <AiOutlineCloudServer size={40} style={{ color: '#009688' }} />
            </Link>

            <Link to="/protection" style={{ textDecoration: 'none' }}>
              <div className="dashboardLinkDiv">
                {loadingStats ?
                  <Breathing width={100} height={40} />
                  :
                  <h3 style={{ color: '#009688', fontWeight: 600, }}>0</h3>
                }
                <span style={{ fontSize: 16, color: '#009688', }}>Protected Hosts</span>
              </div>
            </Link>
          </div>


        </div>


        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap:'wrap' }}>


          <div className="theCards"  style={{
            display: 'flex', flexDirection: 'column', borderRadius: 10, padding: 20, marginTop: 20,
            width: '49%', 
          }}>
            

            <div className="vulnDistroChartContainer" 
              style={{ flexDirection: 'row', justifyContent: 'center', alignSelf: 'center', alignItems:'center' }}>

              <h4 style={{ color: '#2f2f3b', textAlign: 'center', marginTop: 10 }}>Vulnerability Distribution</h4>
              <p style={{fontSize:12, textAlign:'center', alignSelf:'center'}}>% Ratios of different vulnerability types </p>
              <hr style={{borderColor:'white'}}/>


              {loadingVulnerabilityDistribution ?


                
                <div style={{display:'flex', flexDirection:'column', height:300, justifyContent:'space-between'}}>
                  <Breathing width={'100%'} height={10} style={{ borderRadius: 150, alignSelf: 'center',  }} />
                  <Breathing width={'80%'} height={10} style={{ borderRadius: 150, alignSelf: 'center', marginTop:5 }} />
                  <Breathing width={'80%'} height={10} style={{ borderRadius: 150, alignSelf: 'center', marginTop:5 }} />
                  <Breathing width={'70%'} height={10} style={{ borderRadius: 150, alignSelf: 'center', marginTop:5 }} />
                  <Breathing width={'60%'} height={10} style={{ borderRadius: 150, alignSelf: 'center',marginTop:5 }} />
                  <Breathing width={'50%'} height={10} style={{ borderRadius: 150, alignSelf: 'center', marginTop:5 }} />
                  <Breathing width={'70%'} height={10} style={{ borderRadius: 150, alignSelf: 'center', marginTop:5 }} />
                  <Breathing width={'60%'} height={10} style={{ borderRadius: 150, alignSelf: 'center',marginTop:5 }} />
                  <Breathing width={'50%'} height={10} style={{ borderRadius: 150, alignSelf: 'center', marginTop:5 }} />
                  <Breathing width={'70%'} height={10} style={{ borderRadius: 150, alignSelf: 'center', marginTop:5 }} />
                  <Breathing width={'60%'} height={10} style={{ borderRadius: 150, alignSelf: 'center',marginTop:5 }} />
                  <Breathing width={'50%'} height={10} style={{ borderRadius: 150, alignSelf: 'center', marginTop:5 }} />
                 </div>

                :

                <>

                  {vulnDistroChartSeries && vulnDistroChartSeries.length > 0
                    ?

                   
                    <div 
                    style={{ display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '50vh', // Use viewport height for the container
                     }}>
                      
                      <div style={{ flex: 1, minWidth: 0, marginTop:100 }}>

                      
                        <Chart options={vulnDistrochartOptions} series={vulnDistroChartSeries} type="pie" width="100%" height="100%" 
                         />

                         </div>

                   </div>
                   
                    :

                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 30, }}>
                    <CgNotes size={40} style={{ color: '#f73164', textAlign: 'center' }} />
                    <text style={{ textAlign: 'center', color: '#f73164', marginTop: 20, fontSize:13 }}>No Data Yet</text>
                  </div>

                  }

                </>
              }

            </div>
          </div>


          <div className="theCards"  style={{
            display: 'flex', flexDirection: 'column', borderRadius: 10, padding: 20, marginTop: 20,
            width: '49%', 
          }}>

            <h4 style={{ color: '#2f2f3b', textAlign: 'center', marginTop: 10 }}>Top Endpoints</h4>
            <p style={{fontSize:12, textAlign:'center', alignSelf:'center'}}>Endpoints with most vulnerabilities</p>
            <hr style={{borderColor:'white'}}/>


            {loadingTopEndpoints ?


                  <div style={{display:'flex', flexDirection:'column', height:300, justifyContent:'space-between'}}>
                        <Breathing width={'100%'} height={10} style={{ borderRadius: 150, alignSelf: 'center',  }} />
                        <Breathing width={'80%'} height={10} style={{ borderRadius: 150, alignSelf: 'center', marginTop:5 }} />
                        <Breathing width={'80%'} height={10} style={{ borderRadius: 150, alignSelf: 'center', marginTop:5 }} />
                        <Breathing width={'70%'} height={10} style={{ borderRadius: 150, alignSelf: 'center', marginTop:5 }} />
                        <Breathing width={'60%'} height={10} style={{ borderRadius: 150, alignSelf: 'center',marginTop:5 }} />
                        <Breathing width={'50%'} height={10} style={{ borderRadius: 150, alignSelf: 'center', marginTop:5 }} />
                        <Breathing width={'100%'} height={10} style={{ borderRadius: 150, alignSelf: 'center',  }} />
                        <Breathing width={'80%'} height={10} style={{ borderRadius: 150, alignSelf: 'center', marginTop:5 }} />
                        <Breathing width={'80%'} height={10} style={{ borderRadius: 150, alignSelf: 'center', marginTop:5 }} />
                        <Breathing width={'70%'} height={10} style={{ borderRadius: 150, alignSelf: 'center', marginTop:5 }} />
                        <Breathing width={'60%'} height={10} style={{ borderRadius: 150, alignSelf: 'center',marginTop:5 }} />
                        <Breathing width={'50%'} height={10} style={{ borderRadius: 150, alignSelf: 'center', marginTop:5 }} />
                </div>
              :

              <>

                {topEndpoints && topEndpoints.length > 0 ?
                  <div style={{ padding: 10, borderRadius: 10 }}>
                    <table className="topEndpointsTable" cellPadding={10} style={{ width: '100%' }}>

                      <thead>
                        <th>ENDPOINT</th>
                        <th>RISK</th>
                        <th>VULN. COUNT</th>
                        <th>SENSITIVE DATA CLASSES</th>
                      </thead>

                      <tbody>

                        {topEndpoints && topEndpoints.map((endpoint) => (

                          <tr>
                            <td>
                              <span style={{ width:350, display:'inline-block',wordWrap: 'break-word' }}>{(extractHostAndEndpoint(endpoint.url)).endpoint}</span><br />
                              <span style={{ fontSize: 13, width:200 }}>{endpoint.name}</span>
                            </td>
                            <td>                       


                            
                              {endpoint.riskScore == 'HIGH' &&

                                <span style={{ color: '#ffffff', background:'#A6001B', padding: 5, borderRadius: 5, fontSize: 13 }}>
                                  {endpoint.riskScore}
                                </span>
                              }

                              {endpoint.riskScore == 'MEDIUM' &&

                                <span style={{ color: '#000000', background:'#FFC300', padding: 5, borderRadius: 5, fontSize: 13 }}>
                                  {endpoint.riskScore}
                                </span>
                              }


                              {endpoint.riskScore == 'LOW' &&

                                <span style={{ color: '#ffffff', background:'#B3FFB3', padding: 5, borderRadius: 5, fontSize: 13 }}>
                                  {endpoint.riskScore}
                                </span>
                              }
                              


                            </td>

                            <td>
                              {endpoint.vulnCount}
                            </td>

                            <td>

                              <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                                {endpoint.piiFields.length}
                              </div>

                            </td>
                          </tr>

                        ))}

                      </tbody>

                    </table>

                    <Link to="/endpoints" style={{ marginLeft: 10, marginTop: 40, marginRight: 45, textDecoration: 'none', display:'none', float: 'right' }}>View All Endpoints</Link>

                  </div>

                  :

                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 30, }}>
                      <CgNotes size={40} style={{ color: '#f73164', textAlign: 'center' }} />
                      <text style={{ textAlign: 'center', color: '#f73164', marginTop: 20, fontSize:13 }}>No Data Yet</text>
                  </div>

                }

              </>

            }

          </div>

        </div>     




        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap:'wrap' }}>


<div className="theCards"  style={{
  display: 'flex', flexDirection: 'column', borderRadius: 10, padding: 20, marginTop: 20,
  width: '49%', 
}}>
  

  <div className="vulnDistroChartContainer" 
    style={{ flexDirection: 'row', justifyContent: 'center', alignSelf: 'center' }}>

    <h4 style={{ color: '#2f2f3b', textAlign: 'center', marginTop: 10 }}>Vulnerability Trends</h4>
    <p style={{fontSize:12, textAlign:'center', alignSelf:'center'}}>Discovery trend of vulnerabilities for last 12 months</p>
    <hr style={{borderColor:'white'}}/>


    {loadingVulnerabilityDistribution ?
      
      <div style={{display:'flex', flexDirection:'column', height:300, justifyContent:'space-between'}}>
        <Breathing width={'100%'} height={10} style={{ borderRadius: 150, alignSelf: 'center',  }} />
        <Breathing width={'80%'} height={10} style={{ borderRadius: 150, alignSelf: 'center', marginTop:5 }} />
        <Breathing width={'80%'} height={10} style={{ borderRadius: 150, alignSelf: 'center', marginTop:5 }} />
        <Breathing width={'70%'} height={10} style={{ borderRadius: 150, alignSelf: 'center', marginTop:5 }} />
        <Breathing width={'60%'} height={10} style={{ borderRadius: 150, alignSelf: 'center',marginTop:5 }} />
        <Breathing width={'50%'} height={10} style={{ borderRadius: 150, alignSelf: 'center', marginTop:5 }} />
        <Breathing width={'70%'} height={10} style={{ borderRadius: 150, alignSelf: 'center', marginTop:5 }} />
        <Breathing width={'60%'} height={10} style={{ borderRadius: 150, alignSelf: 'center',marginTop:5 }} />
        <Breathing width={'50%'} height={10} style={{ borderRadius: 150, alignSelf: 'center', marginTop:5 }} />
        <Breathing width={'70%'} height={10} style={{ borderRadius: 150, alignSelf: 'center', marginTop:5 }} />
        <Breathing width={'60%'} height={10} style={{ borderRadius: 150, alignSelf: 'center',marginTop:5 }} />
        <Breathing width={'50%'} height={10} style={{ borderRadius: 150, alignSelf: 'center', marginTop:5 }} />
       </div>

      :

      <>

        {lineChartSeries && lineChartSeries.length > 0
        && !(lineChartSeries[0].data.every(value => value === 0)
              && lineChartSeries[1].data.every(value => value === 0)
              && lineChartSeries[2].data.every(value => value === 0)
              && lineChartSeries[3].data.every(value => value === 0)
              && lineChartSeries[4].data.every(value => value === 0)
              && lineChartSeries[5].data.every(value => value === 0)
              && lineChartSeries[6].data.every(value => value === 0)
              && lineChartSeries[7].data.every(value => value === 0)
              && lineChartSeries[8].data.every(value => value === 0)
              && lineChartSeries[9].data.every(value => value === 0)
              && lineChartSeries[10].data.every(value => value === 0)
              && lineChartSeries[11].data.every(value => value === 0)
              && lineChartSeries[12].data.every(value => value === 0)
              && lineChartSeries[13].data.every(value => value === 0)
              && lineChartSeries[14].data.every(value => value === 0)
              && lineChartSeries[15].data.every(value => value === 0)
              && lineChartSeries[16].data.every(value => value === 0)
              && lineChartSeries[17].data.every(value => value === 0)
              )
          ?

         
          <div 
          style={{ display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '60vh', // Use viewport height for the container
           }}>
            
            <div style={{ flex: 1, minWidth: 0, marginTop:100 }}>

            
              <Chart options={lineChartOptions} series={lineChartSeries} type="line" width="100%" height="100%" 
               />
               

               </div>

         </div>
         
          :

          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 30, }}>
          <CgNotes size={40} style={{ color: '#f73164', textAlign: 'center' }} />
          <text style={{ textAlign: 'center', color: '#f73164', marginTop: 20, fontSize:13 }}>No Data Yet</text>
        </div>

        }

      </>
    }

  </div>
</div>



<div className="theCards"  style={{
  display: 'flex', flexDirection: 'column', borderRadius: 10, padding: 20, marginTop: 20,
  width: '49%', 
}}>  



  <div className="vulnDistroChartContainer" 
              style={{ flexDirection: 'row', justifyContent: 'center', alignSelf: 'center' }}>

              <h4 style={{ color: '#2f2f3b', textAlign: 'center', marginTop: 10 }}>Severity Distribution</h4>
              <p style={{fontSize:12, textAlign:'center', alignSelf:'center'}}>Distribution of severity of all found vulnerabilities</p>
              <hr style={{borderColor:'white'}}/>


              {loadingVulnerabilityDistribution ?


                
                <div style={{display:'flex', flexDirection:'column', height:300, justifyContent:'space-between'}}>
                  <Breathing width={'100%'} height={10} style={{ borderRadius: 150, alignSelf: 'center',  }} />
                  <Breathing width={'80%'} height={10} style={{ borderRadius: 150, alignSelf: 'center', marginTop:5 }} />
                  <Breathing width={'80%'} height={10} style={{ borderRadius: 150, alignSelf: 'center', marginTop:5 }} />
                  <Breathing width={'70%'} height={10} style={{ borderRadius: 150, alignSelf: 'center', marginTop:5 }} />
                  <Breathing width={'60%'} height={10} style={{ borderRadius: 150, alignSelf: 'center',marginTop:5 }} />
                  <Breathing width={'50%'} height={10} style={{ borderRadius: 150, alignSelf: 'center', marginTop:5 }} />
                  <Breathing width={'70%'} height={10} style={{ borderRadius: 150, alignSelf: 'center', marginTop:5 }} />
                  <Breathing width={'60%'} height={10} style={{ borderRadius: 150, alignSelf: 'center',marginTop:5 }} />
                  <Breathing width={'50%'} height={10} style={{ borderRadius: 150, alignSelf: 'center', marginTop:5 }} />
                  <Breathing width={'70%'} height={10} style={{ borderRadius: 150, alignSelf: 'center', marginTop:5 }} />
                  <Breathing width={'60%'} height={10} style={{ borderRadius: 150, alignSelf: 'center',marginTop:5 }} />
                  <Breathing width={'50%'} height={10} style={{ borderRadius: 150, alignSelf: 'center', marginTop:5 }} />
                 </div>

                :

                <>

                  {severityChartSeries && severityChartSeries.length > 0 && !(severityChartSeries[0] == 0  && severityChartSeries[1] == 0  && severityChartSeries[2] == 0 && severityChartSeries[3] == 0 )  
                    ?

                   
                    <div 
                    style={{ display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '50vh', // Use viewport height for the container
                     }}>
                      
                      <div style={{ flex: 1, minWidth: 0, marginTop:100 }}>

                      
                        <Chart options={severitychartOptions} series={severityChartSeries} type="pie" width="100%" height="100%" 
                         />

                         </div>

                   </div>
                   
                    :

                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 30, }}>
                    <CgNotes size={40} style={{ color: '#f73164', textAlign: 'center' }} />
                    <text style={{ textAlign: 'center', color: '#f73164', marginTop: 20, fontSize:13 }}>No Data Yet</text>
                  </div>

                  }

                </>
              }

            </div>

</div>



</div> 

      </CContainer>
    </>
  )
}

export default UserDashboard